import axios, { AxiosResponse } from 'axios';
import AuthService from 'services/AuthService';
import history from 'services/routerHistory';

const API_CALL_TIMEOUT = 30000;

// webpackDevServer.js redirects /api to http://localhost:5000
const BASE_URL = process.env.API_HOST || '/api';
const BASE_REFRESH_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:5000' : process.env.API_HOST;
const authPath = '/account/signin';

const timezoneOffset = -(new Date().getTimezoneOffset());

const REFRESH_API = axios.create({
  baseURL: BASE_REFRESH_URL,
  timeout: API_CALL_TIMEOUT,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
  },
  withCredentials: true,
});

const API = axios.create({
  baseURL: BASE_URL,
  timeout: API_CALL_TIMEOUT,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Timezone-Offset': timezoneOffset, // custom header for dates validations
  },
});

const handleRefresh = async (config: any) => {
  AuthService.setTokenIsRefreshing('true');
  try {
    const token = await REFRESH_API.post('/account/refresh-token', {});
    const { accessToken } = token.data.data;

    AuthService.setToken(accessToken);
    AuthService.setTokenIsRefreshing('false');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
  } catch {
    AuthService.resetToken();
    history.push(authPath);
  }
};

let refresher: any;

API.interceptors.request.use(
  async (config) => {
    const isExpired = AuthService.isAccessTokenExpired();
    const isRefreshing = AuthService.getTokenIsRefreshing();

    if (isExpired && !isRefreshing && !refresher) {
      refresher = handleRefresh(config);
      await refresher;
    } else if (isRefreshing && refresher) {
      await refresher;
    }
    refresher = undefined;
    const token = AuthService.getToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

API.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      const { pathname } = window.location;

      AuthService.resetToken();
      if (pathname !== authPath) {
        sessionStorage.setItem('pendingRoute', pathname);
        history.push(authPath);
      }
    }

    return Promise.reject(error);
  },
);

// trim spaces
const normalizeData = (data: any) => {
  if (!data || Object.keys(data).length === 0) {
    // it could be multipart/form-data
    // so we should return original object
    return data;
  }
  const normalizedData = Object.keys(data).reduce((result: {[key: string]: string;}, field: string) => {
    let value = data[field];

    if (typeof value === 'string') {
      value = value.trim();
    }
    result[field] = value;

    return result;
  }, {});

  return normalizedData;
};

// normalization for axios http-errors
const castError = (error: any) => {
  const { response, config, isAxiosError } = error;

  if (!config) {
    console.error('something wrong:', error); // eslint-disable-line no-console
  }
  const { url } = config || {};

  if (!isAxiosError || !response) {
    console.error('ApiClient: Something went wrong:', error); // eslint-disable-line no-console

    return error;
  }

  const { status, statusText, data } = response;

  if (process.env.NODE_ENV === 'development') {
    console.error(`ApiClient: ${url}\nstatus, statusText, data:`, status, statusText, JSON.stringify(data)); // eslint-disable-line no-console
  }

  // log backend 500 error
  if (status === 500) {
    if (data.error) {
      console.error('error:', data.error); // eslint-disable-line no-console
    }
    if (data.stackTrace) {
      console.error('stackTrace:', data.stackTrace); // eslint-disable-line no-console
    }
  }

  return { status, statusText, ...data };
};

export interface Response<T> {
  data: T
}

class ApiClient {
  async call<T>({ data, method, url, params, headers, baseURL, onUploadProgress, withCredentials, responseType }: any): Promise<AxiosResponse<Response<T>>> {
    const preparedData = normalizeData(data);
    const callParams = {
      method,
      url,
      params,
      onUploadProgress,
      withCredentials,
      baseURL: baseURL || API.defaults.baseURL,
      headers: {
        ...API.defaults.headers,
        ...headers,
      },
      data: preparedData,
      responseType,
    };

    try {
      if (method === 'get') {
        return API.get<Response<T>>(url, callParams);
      }

      return API(callParams);
    } catch (error) {
      return Promise.reject(castError(error));
    }
  }
}

export default ApiClient;
